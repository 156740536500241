<i18n>
{
  "de": {
    "portfolios": "Portfolios",
    "editButtonLabel": "Benutzer bearbeiten",
    "editUser": "Benutzerdaten bearbeiten",
    "changePassword": "Password ändern",
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "noPortfolios": "Keine Portfolios",
    "addToPortfolio": "Zugriff auf bestehendes Portfolio geben",
    "userViewLabel": "Benutzer-Ansicht",
    "pendingMessage": "Wird gespeichert...",
    "activityTitle": "Aktivität",
    "lastActivitiy": "Letzte Aktivität",
    "lastLogin": "Letztes Login",
    "deleteConfirmation": "Wollen Sie den Zugriff von Portfolio \"{name}\" wirklich entziehen?",
    "userDataTitle": "Benutzerdaten",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "companyName": "Firma",
    "email": "E-Mail Adresse"
  }
}
</i18n>

<template>
  <MainLayout :ready="allUsersReady" class="admin-user-page">
    <template #default>
      <p v-if="updatePasswordError">{{ updatePasswordError }}</p>
      <p v-if="removePortfolioError">{{ removePortfolioError }}</p>
      <p v-if="updatePasswordPending || removePortfolioPending">{{ $t('pendingMessage') }}</p>
      <template v-else>
        <h1>{{ user.first_name }} {{ user.last_name }}</h1>
        <div v-if="editPassword">
          <h2>{{ $t('changePassword') }}</h2>
          <form class="password-form" @keydown.esc="onCancel">
            <TextInput v-model="password" password :min-length="6" :edit="true" />
            <ButtonWrapper>
              <button class="button" type="submit" :disabled="submitDisabled" @click.prevent="onSavePassword">
                {{ $t('saveButtonLabel') }}
              </button>
              <button class="button" @click.prevent="onCancel">{{ $t('cancelButtonLabel') }}</button>
            </ButtonWrapper>
          </form>
        </div>
        <div v-else>
          <ButtonWrapper>
            <a class="button" @click.prevent="onEditUser">{{ $t('editButtonLabel') }}</a>
            <a class="button" @click.prevent="onEditPassword">{{ $t('changePassword') }}</a>
          </ButtonWrapper>
          <DetailList :title="$t('userDataTitle')" class="user-data-table">
            <DetailListItem :title="$t('firstName')">{{ user.first_name }}</DetailListItem>
            <DetailListItem :title="$t('lastName')">{{ user.last_name }}</DetailListItem>
            <DetailListItem :title="$t('companyName')">{{ user.company_name }}</DetailListItem>
            <DetailListItem :title="$t('email')">{{ user.email }}</DetailListItem>
          </DetailList>
          <DetailList :title="$t('activityTitle')" class="user-data-table">
            <DetailListItem :title="$t('lastActivitiy')"
              ><span v-if="user.last_seen">{{ user.last_seen | luxon({ output: 'full' }) }}</span
              ><span v-else>-</span></DetailListItem
            >
            <DetailListItem :title="$t('lastLogin')"
              ><span v-if="user.last_seen">{{ user.current_login_at | luxon({ output: 'full' }) }}</span
              ><span v-else>-</span></DetailListItem
            >
          </DetailList>

          <h2>{{ $t('portfolios') }}</h2>
          <ul v-if="hasPortfolios" class="portfolios-list">
            <li v-for="portfolio in user.portfolios" :key="portfolio.id">
              <router-link :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } }"
                ><strong>{{ portfolio.name }}</strong></router-link
              >
              <router-link :to="{ name: 'portfolioBuildings', params: { portfolio_id: portfolio.id } }">{{
                $t('userViewLabel')
              }}</router-link>
              <span>{{ portfolio.role }}</span>
              <ListButtonWrapper>
                <ListDeleteButton @click="onRemovePortfolio(portfolio)" />
              </ListButtonWrapper>
            </li>
          </ul>
          <p v-else>{{ $t('noPortfolios') }}</p>

          <ButtonWrapper>
            <router-link class="button" :to="{ name: 'adminAddPortfolioToUser', params: { id: user.id } }">{{
              $t('addToPortfolio')
            }}</router-link>
          </ButtonWrapper>
        </div>
      </template>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin.vue'
import AdminUserMixin from '@/pages/vuex-mixins/AdminUserMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'

export default {
  name: 'adminUser',

  mixins: [
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
    AdminUserMixin, // Provides: user
  ],

  components: {
    ListButtonWrapper,
    ListDeleteButton,
    MainLayout,
    ButtonWrapper,
    TextInput,
    DetailListItem,
    DetailList,
  },

  data() {
    return {
      pageTitle: null,
      password: '',
      editPassword: false,
      updatePasswordPending: false,
      updatePasswordError: null,
      removePortfolioPending: false,
      removePortfolioError: null,
    }
  },

  computed: {
    hasPortfolios() {
      return Boolean(this.user && this.user.portfolios && this.user.portfolios.length)
    },

    submitDisabled() {
      return this.password === null || this.password.length < 6
    },
  },

  watch: {
    user() {
      this.pageTitle = `${this.user.first_name} ${this.user.last_name}`
      this.$emit('updateHead')
    },
  },

  methods: {
    ...mapActions({
      updateUserPassword: 'users/updatePasswordById',
      removePortfolioUser: 'portfolio/removePortfolioUser',
    }),

    onEditUser() {
      this.$router.push({ name: 'adminEditUser', params: { user_id: this.user_id } })
    },

    onCancel() {
      this.editPassword = false
      this.password = ''
    },

    async onSavePassword() {
      try {
        this.updatePasswordPending = true
        this.updatePasswordError = null
        await this.updateUserPassword({ id: this.user_id, password: this.password })
      } catch (error) {
        this.updatePasswordError = error
      }
      this.updatePasswordPending = false
      this.editPassword = false
    },

    onEditPassword() {
      this.editPassword = true
    },

    async onRemovePortfolio(portfolio) {
      const confirmationText = this.$t('deleteConfirmation', { name: portfolio.name })
      if (confirm(confirmationText)) {
        try {
          this.removePortfolioPending = true
          this.removePortfolioError = null
          await this.removePortfolioUser({ portfolioId: portfolio.id, userId: this.user_id })
        } catch (error) {
          this.removePortfolioError = error
        }
        this.removePortfolioPending = false
      }
    },
  },

  head() {
    return {
      title: () => {
        if (this.pageTitle) {
          return { inner: this.pageTitle }
        }
        if (this.user) {
          return { inner: `${this.user.first_name} ${this.user.last_name}` }
        }
      },
    }
  },
}
</script>

<style>
.admin-user-page .user-data-table {
  max-width: 600px;
  margin-bottom: var(--spacing-l);
}

.admin-user-page .password-form {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-l);
  row-gap: var(--spacing-s);
  margin-bottom: var(--spacing-xl);
}

.admin-user-page .password-form button:disabled {
  color: var(--error-color);
  border: var(--box-error-border);
}

.admin-user-page .portfolios-list {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: var(--spacing-l);
  row-gap: var(--spacing-s);
  justify-content: start;
  align-items: center;
}

.admin-user-page .portfolios-list li {
  display: contents;
}

.admin-user-page .portfolios-list li:first-child::before,
.admin-user-page .portfolios-list li::after {
  content: '';
  grid-column: 1 / -1;
  border-bottom: var(--box-border);
}

.admin-user-page .portfolios-list li > :first-child {
  grid-column-start: 1;
}

.admin-user-page .portfolios-list li h3 {
  margin-bottom: 0 !important;
}

.admin-user-page .portfolios-list li .button-section {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: baseline;
  gap: var(--spacing-m);
}

.admin-user-page .portfolios-list li .button-section {
  align-self: center;
}

.admin-user-page .portfolios-list li .button-section a {
  opacity: 0.7;
}

.admin-user-page .portfolios-list li .button-section a:hover {
  opacity: 1;
}
</style>
